import { FAB } from 'react-native-paper';
import { observer } from "mobx-react-lite"
import { StyleSheet } from 'react-native';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const styles = StyleSheet.create({
  fab: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: 16,
  },
});

export const BackButton = observer(() => {
  const navigation = useNavigation();

  const goBack = () => {
    navigation.navigate('Home');
  }

  const isTouchDevice =
    ((Platform.OS !== 'web') ||
      ('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));

  return (
    !isTouchDevice && <FAB
      style={styles.fab}
      icon={'arrow-left'}
      onPress={goBack}
    />
  );
})
