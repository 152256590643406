import { observer } from "mobx-react-lite"
import { ActivityIndicator, View, StyleSheet } from 'react-native';
import {
  Dialog,
  MD3Colors,
  Text,
} from 'react-native-paper';

import { useStores } from "../../src/@stores/useStores"

const styles = StyleSheet.create({
  flexing: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: 16,
  },
});

export const ProgressDialog = observer(() => {
  const { progressDialogStore } = useStores();

  return (
    <Dialog visible={progressDialogStore.isVisible}>
      <Dialog.Title>{progressDialogStore.title}</Dialog.Title>
      <Dialog.Content>
        <View style={styles.flexing}>
          <ActivityIndicator
            color={MD3Colors.tertiary30}
            size={48}
            style={styles.marginRight}
          />
          <Text variant='bodyMedium'>{progressDialogStore.text}</Text>
        </View>
      </Dialog.Content>
    </Dialog>
  )
})
