import { observer } from "mobx-react-lite"
import { Text } from 'react-native-paper';

import { useStores } from '../../src/@stores/useStores';

export const SongLine = observer((props) => {
  const { fontSizeStore } = useStores();

  const red = { color: 'red' }
  const { line } = props;
  let { words, chords } = line;

  const fontSize = 18 + fontSizeStore.modifier;

  return (
    <Text style={{fontSize: fontSize}} key={line.line}>
      <Text>{words} </Text>
      <Text style={red}>{chords}</Text>
    </Text>
  );
})
