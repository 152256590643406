import * as React from 'react';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Provider, MD3LightTheme, adaptNavigationTheme } from 'react-native-paper';
import { observer } from 'mobx-react-lite';

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { HomeScreen } from './src/@screens/HomeScreen'
import { SongScreen } from './src/@screens/SongScreen'
import { GlobalPortal } from './src/@components/GlobalPortal'
import { wakeLockOn } from './src/@utils/wakeLock'

const Stack = createStackNavigator();
const { LightTheme } = adaptNavigationTheme({ reactNavigationLight: DefaultTheme });

const linking = {
  config: {
    screens: {
      Home: '',
      Song: 'song/:id',
    },
  },
};

const App = observer(() => {
  return (
    <Provider theme={MD3LightTheme}>
      <NavigationContainer theme={LightTheme} linking={linking}>
        <GlobalPortal />
        <Stack.Navigator initialRouteName="Home" screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Home" options={{title: "Śpiewnik"}} component={HomeScreen} />
          <Stack.Screen name="Song" component={SongScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  );
});

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

wakeLockOn();
