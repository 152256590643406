import { FAB } from 'react-native-paper';
import { observer } from "mobx-react-lite"
import { StyleSheet } from 'react-native';

import { useStores } from '../../src/@stores/useStores';

const styles = StyleSheet.create({
  fab: {
    position: 'fixed',
  },
});

export const SongFab = observer(() => {
  const { songFabStore, comingSoonDialogStore, fontSizeStore, songsStore, listenerStore } = useStores();

  const toggleFontSizeBar = () => {
    fontSizeStore.toggleBar();
  }

  const comingSoon = () => {
    comingSoonDialogStore.show();
  }

  const addFavorite = () => {
    songsStore.addFavorite();
  }

  const removeFavorite = () => {
    songsStore.removeFavorite();
  }

  const favoriteAction = songsStore.isFavorite ?
    { icon: 'star-outline', label: 'Usuń z ulubionych', onPress: removeFavorite } :
    { icon: 'star', label: 'Dodaj do ulubionych', onPress: addFavorite }

  const addToPlaylist = () => {
    songsStore.addToPlaylist();
  }

  const removeFromPlaylist = () => {
    songsStore.removeFromPlaylist();
  }

  const listenerToggle = () => {
    listenerStore.toggle();
  }

  const playlistAction = songsStore.isOnPlaylist ?
    { icon: 'playlist-remove', label: 'Usuń z playlisty', onPress: removeFromPlaylist } :
    { icon: 'playlist-plus', label: 'Dodaj do playlisty', onPress: addToPlaylist }

  const listenerAction = listenerStore.enabled ?
    { icon: 'wifi-off', label: 'Wyłącz nasłuchiwanie', onPress: listenerToggle } :
    { icon: 'wifi', label: 'Włącz nasłuchiwanie', onPress: listenerToggle }

  return (
    <FAB.Group
      style={styles.fab}
      open={songFabStore.open}
      icon={songFabStore.open ? 'close' : 'plus'}
      actions={[
        favoriteAction,
        playlistAction,
        { icon: 'music', label: 'Tonacja', onPress: comingSoon },
        { icon: 'format-size', label: 'Rozmiar czcionki', onPress: toggleFontSizeBar },
        listenerAction,
      ]}
      onStateChange={(x) => songFabStore.setOpen(x.open)}
    />
  );
})
