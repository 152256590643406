import { Text } from 'react-native-paper';
import { SafeAreaView, ScrollView, StyleSheet, Linking } from 'react-native';
import Icon from 'react-native-paper/src/components/Icon'
import { observer } from "mobx-react-lite"

import { screenStyle } from '../../src/@styles/screenStyle';

const styles = StyleSheet.create({
  scrollView: {
    paddingLeft: 16
  },
  headings: {
    paddingTop: 16
  },
  bodies: {
    paddingTop: 16
  },
});

const iconSize = 30
const arrowColor = '#9E9E9E'

const chromeInstructions = `1. Otwórz tę stronę w przeglądarce Google Chrome. Możesz też użyć innej przeglądarki, ale wtedy poniższe kroki mogą się nieznacznie różnić.
2. Jeżeli na dole strony zobaczysz komunikat "Dodaj aplikację Śpiewnik do ekranu głównego", kliknij w niego.
3. Jeżeli nie zobaczysz komunikatu, kliknij w menu przeglądarki (trzy kropki na prawo od pola adresu) i wybierz opcję "Zainstaluj aplikację Śpiewnik" / "Dodaj do ekranu głównego".
4. Kliknij "Zainstaluj" / "Dodaj".
5. Poczekaj na powiadomienie o zainstalowaniu aplikacji.
6. Otwórz aplikację Śpiewnik z poziomu ekranu głównego lub menu aplikacji.
`
const iosInstructions = `1. Otwórz tę stronę w przeglądarce Safari. W przypadku urządzeń Apple nie ma możliwości instalacji aplikacji z wykorzystaniem innej przeglądarki.
2. Kliknij w menu przeglądarki (strzałka w górę pod polem adresu).
3. Wybierz opcję "Do ekranu początkowego".
4. Kliknij "Dodaj".
5. Otwórz aplikację Śpiewnik z poziomu ekranu początkowego.
`
const listeningInstructions = `Aby skorzystać z funkcji nasłuchiwania, należy zainstalować na urządzeniu ze śpiewnikiem certyfikat root oraz uruchomić na komputerze w sieci lokalnej program "Broker".
`

export const InstallInstructions = observer(() => {
  return (
    <SafeAreaView style={screenStyle}>
      <ScrollView style={styles.scrollView}>
        <Text variant="displayMedium" style={styles.headings}>
          <Text>Witamy </Text>
          <Icon source="hand-wave-outline" size={40} />
        </Text>

        <Text variant="bodyLarge" style={styles.bodies}>
          Aby zainstalować aplikację na swoim urządzeniu, wykonaj następujące kroki:
        </Text>

        <Text variant="headlineMedium" style={styles.headings}>
          Android lub komputer
        </Text>

        <Text style={styles.bodies}>
          <Icon source="google-chrome" size={iconSize} />
          <Icon source="arrow-right-thin" size={iconSize} color={arrowColor} />
          <Icon source="dots-vertical" size={iconSize} />
          <Icon source="arrow-right-thin" size={iconSize} color={arrowColor} />
          <Icon source="cellphone" size={iconSize} />
        </Text>

        <Text variant="bodyLarge" style={styles.bodies}>
          {chromeInstructions}
        </Text>

        <Text variant="headlineMedium" style={styles.headings}>
          iPhone lub iPad
        </Text>

        <Text style={styles.bodies}>
          <Icon source="apple-safari" size={iconSize} />
          <Icon source="arrow-right-thin" size={iconSize} color={arrowColor} />
          <Icon source="export-variant" size={iconSize} />
          <Icon source="arrow-right-thin" size={iconSize} color={arrowColor} />
          <Icon source="plus-box-outline" size={iconSize} />
        </Text>

        <Text variant="bodyLarge" style={styles.bodies}>
          {iosInstructions}
        </Text>

        <Text variant="headlineMedium" style={styles.headings}>
          Nasłuchiwanie
        </Text>

        <Text variant="bodyLarge" style={styles.bodies}>
          {listeningInstructions}
        </Text>

        <Text style={{color: 'blue'}} onPress={() => Linking.openURL('https://github.com/songbook5000/songbook_broker_public/blob/main/local_certs/minica.pem')}>
          Certyfikat root
        </Text>

        <Text style={{color: 'blue'}} onPress={() => Linking.openURL('https://raw.githubusercontent.com/songbook5000/songbook_broker_public/main/build/broker_windows.exe')}>
          Broker windows
        </Text>

        <Text style={{color: 'blue'}} onPress={() => Linking.openURL('https://raw.githubusercontent.com/songbook5000/songbook_broker_public/main/build/broker_macos_intel')}>
          Broker macos (intel)
        </Text>

        <Text style={{color: 'blue'}} onPress={() => Linking.openURL('https://raw.githubusercontent.com/songbook5000/songbook_broker_public/main/build/broker_macos_m123')}>
          Broker macos (M1, itp.)
        </Text>
      </ScrollView>
    </SafeAreaView>
  );
})
