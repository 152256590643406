import { makeAutoObservable } from "mobx"

export class ComingSoonDialogStore {
  isVisible = false

  constructor() {
    makeAutoObservable(this)
  }

  show = () => {
    this.isVisible = true
  }

  hide = () => {
    this.isVisible = false
  }
}
