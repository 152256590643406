import * as React from 'react';
import { SafeAreaView } from 'react-native';
import { Text, Searchbar } from 'react-native-paper';
import { observer } from "mobx-react-lite"
import { useNavigation } from '@react-navigation/native';

import { useStores } from '../../src/@stores/useStores';
import { screenStyle } from '../../src/@styles/screenStyle';
import { headingStyle } from '../../src/@styles/headingStyle';
import { SongsList } from '../../src/@components/SongsList';
import { setSearchInputRef } from '../../src/@utils/keyboardShortcuts';

export const SearchScreen = observer(() => {
  const { songsStore } = useStores();

  const navigation = useNavigation();
  const ref = React.useRef(null);
  setSearchInputRef(ref);

  const onSubmitEditing = () => {
    songsStore.setFirstAsActive();
    navigation.navigate('Song', { id: songsStore.activeSong.id });
  }

  return (
    <SafeAreaView style={screenStyle}>
      { !songsStore.searchQuery && <Text style={headingStyle} variant="displayMedium">
        Śpiewnik
      </Text> }
      <Searchbar
        placeholder="Szukaj..."
        value={songsStore.searchQuery}
        onChangeText={songsStore.search}
        style={{ margin: 4 }}
        elevation={0}
        ref={ref}
        onSubmitEditing={onSubmitEditing}
      />
      <SongsList data={songsStore.filteredSongs} />
    </SafeAreaView>
  )
})
