export const parseSong = (songText) => {
  // split text into lines
  const lines = songText.split("\n")

  // convert lines into line objects
  return lines.map((line, idx) => {
    // words are first 60 chars
    const words = line.slice(0, 60).replace(/\s+/g, ' ').trim();
    // chords starts from 61 char
    const chords = line.slice(60).replace(/\s+/g, ' ').trim();

    return { idx, words, chords }
  })
}
