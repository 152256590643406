// import { useContext, createContext } from "react";

import { ComingSoonDialogStore } from './ComingSoonDialogStore';
import { FontSizeStore } from './FontSizeStore';
import { ProgressDialogStore } from './ProgressDialogStore';
import { SongFabStore } from './SongFabStore';
import { SongsStore } from './SongsStore';
import { ListenerStore } from './ListenerStore';

export const comingSoonDialogStore = new ComingSoonDialogStore();
export const fontSizeStore = new FontSizeStore();
export const progressDialogStore = new ProgressDialogStore();
export const songFabStore = new SongFabStore();
export const songsStore = new SongsStore();
export const listenerStore = new ListenerStore();

export const allStores = {
  comingSoonDialogStore: comingSoonDialogStore,
  fontSizeStore: fontSizeStore,
  progressDialogStore: progressDialogStore,
  songFabStore: songFabStore,
  songsStore: songsStore,
  listenerStore: listenerStore,
};

// The approach using useContext
// const storesContext = createContext(allStores);
// export const useStores = () => useContext(storesContext);

// Simplified approach
export const useStores = () => allStores;
