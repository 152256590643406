import { makeAutoObservable } from "mobx"

export class ProgressDialogStore {
  isVisible = false
  title = "Progress"
  text = "Wait..."

  constructor() {
    makeAutoObservable(this)
  }

  show = (title, text) => {
    this.title = title
    this.text = text
    this.isVisible = true
  }

  hide = () => {
    this.isVisible = false
  }
}
