import io from 'socket.io-client';

import { listenerStore } from '../../../src/@stores/useStores';

const ADDRESSES = [
  'https://songbook-broker.local:3016',
  'https://192.168.100.250:3016',
  'https://192.168.100.240:3016',
  'https://192.168.100.230:3016',
  'https://192.168.100.220:3016',
  'https://192.168.100.210:3016',
]
const ROOM = 'room1';
const secure = true;

let socket = null;
let ADDRESS = null;
let VALID_TO = null;

const create = () => {
  if (ADDRESS === null) { return; }

  const extraHeaders = { room: ROOM };
  socket = io(ADDRESS, { extraHeaders, secure });

  // Handle connections
  socket.on("connect", () => {
    console.log('Broker: connected');
    listenerStore.setState(socket);
  });

  // Handle disconnections
  socket.on("disconnect", (reason, details) => {
    console.log('Broker: disconnected', reason, details);
    listenerStore.setState(socket);
  });

  // Handle connection errors
  socket.on("connect_error", (error) => {
    listenerStore.setState(socket);
    if (socket.active) {
      // temporary failure, the socket will automatically try to reconnect
      console.log("Broker: temporary failure", error);
    } else {
      // the connection was denied by the server
      // in that case, `socket.connect()` must be manually called in order to reconnect
      console.log("Broker: permanent failure", error);
    }
  });

  // Pass the received song to the listenerStore
  socket.on('CHOOSE_SONG_BROADCAST', (data) => {
    listenerStore.receive(data);
  });
};

// Discover the broker address. Check all addresses in ADDRESSES array
export const detect = async () => {
  if (ADDRESS !== null) { return; }

  for (const address of ADDRESSES) {
    try {
      const resp = await fetch(`${address}/cert`);
      ADDRESS = address;
      const body = await resp.json();
      VALID_TO = body.validTo;
      listenerStore.setValidTo(VALID_TO);
      break;
    } catch (error) {
      console.log('Broker: invalid address', address);
    }
  }

  console.log('Broker: discovery results', ADDRESS, VALID_TO);
};

// Connect to the broker
// Detect the address and create the socket if it doesn't exist or reconnect if it does
const connect = async () => {
  if (socket === null) {
    await detect();
    create();
  } else {
    socket.connect();
  }
};

// Disconnect from the broker
const disconnect = () => {
  if (socket !== null) {
    socket.disconnect();
  }
}

// Emit an event to the broker with additional metadata
const emit = (event, data) => {
  if (socket === null) {
    return;
  }
  const metadata = {
    room: ROOM,
    socketId: socket.id,
  };

  socket.emit(event, { data, metadata });
};

export { connect, disconnect, emit };
