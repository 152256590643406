import { Platform } from 'react-native';

const isStandaloneWindow = () => {
  return Platform.OS === 'web' && window.matchMedia('(display-mode: standalone)').matches
}

const isNative = () => {
  return Platform.OS !== 'web';
}

const isDevelopment = () => {
  return __DEV__;
}

const hasCookie = () => {
  return Platform.OS === 'web' && document.cookie.includes('isPwa=true');
}

const hasParam = () => {
  return Platform.OS === 'web' && window.location.search.includes('mode=pwa');
}

const setCookie = () => {
  if (Platform.OS === 'web') {
    document.cookie = 'isPwa=true';
  }
}

export const checkPwa = () => {
  const result = isStandaloneWindow() || isNative() || isDevelopment() || hasCookie() || hasParam();

  if (result) { setCookie() }

  return result;
}
