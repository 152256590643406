import { List } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { observer } from "mobx-react-lite"

import { useStores } from '../../src/@stores/useStores';

export const SongListItem = observer((props) => {
  const navigation = useNavigation();
  const { songsStore } = useStores();
  const { song } = props;

  const setAsActive = () => {
    songsStore.setActive(song);
    navigation.navigate('Song', { id: song.id });
  }

  return (
    <List.Item title={song.tytul} onPress={setAsActive} />
  )
})
