import axios from 'axios';

const BASE_URL = "https://songbook-server.herokuapp.com";

const apiClient = axios.create({
  baseURL: BASE_URL,
});

const getSongs = async () => {
  const { data } = await apiClient.get("/songs?key=xcRt.45xWesyxcY787.34_23Ya.Zx");
  return data.filter((s) => { return s.status != '2' })
};

export { getSongs };
