import { Platform } from 'react-native';
import NoSleep from '../../src/@vendor/nosleep.js/index';

export const wakeLockOn = () => {
  if (Platform.OS !== 'web') { return; }

  const noSleep = new NoSleep();
  const toggleEl = document.querySelector("#root");
  let needWakeLock = true

  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      needWakeLock = false
    } else {
      needWakeLock = true
    }
  });

  const wakeIfNeeded = () => {
    if (needWakeLock) {
      noSleep.enable();
      needWakeLock = false
    }
  }

  const isSafari = () => {
    return typeof navigator !== "undefined" &&
      /Safari/.test(navigator.userAgent) &&
      /Apple/.test(navigator.vendor);
  }

  // On Safari we can't request a wake lock until the user has interacted with the page
  if (!isSafari()) {
    wakeIfNeeded()
  }
  // When user clicks the page, request wake lock if needed
  toggleEl.addEventListener('click', wakeIfNeeded, false);
}
