import { FAB } from 'react-native-paper';
import { observer } from "mobx-react-lite"
import { StyleSheet } from 'react-native';

import { useStores } from '../../src/@stores/useStores';

const styles = StyleSheet.create({
  fab: {
    position: 'fixed',
    top: 0,
    right: 0,
    margin: 16,
  },
});

export const ListenerFab = observer(() => {
  const { songsStore, listenerStore } = useStores();

  let icon = 'wifi-off';
  let label = 'Łączenie...';
  let disabled = true;

  switch (listenerStore.machineState) {
    case 'DISCONNECTED':
      icon = 'wifi-sync';
      label = 'Łączenie...';
      disabled = true;
      break;
    case 'READY_TO_SEND':
      icon = 'send';
      label = 'Wyślij';
      disabled = false;
      break;
    case 'RECEIVED':
      icon = 'check';
      label = null;
      disabled = true;
      break;
    case 'SENDING':
      icon = 'send-check';
      label = 'Wysyłanie...';
      disabled = true;
      break;
    default:
      icon = 'wifi-off';
      label = 'Błąd';
      disabled = true;
  };

  const size = disabled ? 'small' : 'medium';

  const sendSong = () => {
    listenerStore.broadcast();
  }

  return (
    listenerStore.enabled && <FAB
      style={styles.fab}
      icon={icon}
      label={label}
      disabled={disabled}
      size={size}
      onPress={sendSong}
    />
  );
})
