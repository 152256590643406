import * as React from 'react';
import { BottomNavigation } from 'react-native-paper';
import { observer } from "mobx-react-lite"

import { SearchScreen } from '../../src/@screens/SearchScreen';
import { FavoritesScreen } from '../../src/@screens/FavoritesScreen';
import { PlaylistScreen } from '../../src/@screens/PlaylistScreen';
import { SettingsScreen } from '../../src/@screens/SettingsScreen';
import { InstallInstructions } from '../../src/@screens/InstallInstructions';
import { checkPwa } from '../../src/@utils/checkPwa';
import { focusSearchInput } from '../../src/@utils/keyboardShortcuts';

export const HomeScreen = observer(() => {
  if (!checkPwa()) { return <InstallInstructions /> }

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'search', title: 'Szukaj', focusedIcon: 'magnify' },
    { key: 'favourites', title: 'Ulubione', focusedIcon: 'heart-outline' },
    { key: 'playlist', title: 'Playlista', focusedIcon: 'playlist-music-outline' },
    { key: 'settings', title: 'Ustawienia', focusedIcon: 'cog-outline' },
  ]);

  const renderScene = BottomNavigation.SceneMap({
    search: SearchScreen,
    favourites: FavoritesScreen,
    playlist: PlaylistScreen,
    settings: SettingsScreen,
  });

  const onTabPress = (e) => {
    if (e.route.key === 'search') {
      focusSearchInput();
    }
  }

  return (
    <BottomNavigation
      navigationState={{ index, routes }}
      onIndexChange={setIndex}
      renderScene={renderScene}
      onTabPress={onTabPress}
    />
  );
});
