import { observer } from "mobx-react-lite";
import { Appbar, Button } from 'react-native-paper';
import { StyleSheet } from 'react-native';

import { useStores } from '../../src/@stores/useStores';

const styles = StyleSheet.create({
  bottom: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
  },
});

export const FontSizeBar = observer(() => {
  const { fontSizeStore } = useStores();

  return (
    <>
    { fontSizeStore.barVisible &&
      <Appbar style={styles.bottom}>
        <Button icon="plus" compact={true} onPress={fontSizeStore.increase}>Powiększ</Button>
        <Button icon="minus" compact={true} onPress={fontSizeStore.decrease}>Zmniejsz</Button>
        <Button icon="reload" compact={true} onPress={fontSizeStore.reset}>Reset</Button>
      </Appbar>
    }
    </>
  );
});
