import { observer } from "mobx-react-lite"
import { StyleSheet } from 'react-native';
import { Button, Dialog, MD3Colors, Text } from 'react-native-paper';

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
  },
});

import { useStores } from "../../src/@stores/useStores"

export const ComingSoonDialog = observer(() => {
  const { comingSoonDialogStore } = useStores();

  const title = 'Wkrótce'
  const content = 'Ta funkcja będzie dostępna wkrótce.'

  return (
    <Dialog onDismiss={comingSoonDialogStore.hide} visible={comingSoonDialogStore.isVisible}>
      <Dialog.Icon icon='wrench' />
      <Dialog.Title style={styles.title}>{title}</Dialog.Title>
      <Dialog.Content>
        <Text variant='bodyMedium'>{content}</Text>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={comingSoonDialogStore.hide}>OK</Button>
      </Dialog.Actions>
    </Dialog>
  )
})
