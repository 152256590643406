import * as React from 'react';
import { SafeAreaView } from 'react-native';
import { Text } from 'react-native-paper';
import { observer } from "mobx-react-lite"
import Icon from 'react-native-paper/src/components/Icon'

import { useStores } from '../../src/@stores/useStores';
import { screenStyle } from '../../src/@styles/screenStyle';
import { headingStyle } from '../../src/@styles/headingStyle';
import { SongsList } from '../../src/@components/SongsList';

const iconSize = 60

export const PlaylistScreen = observer(() => {
  const { songsStore } = useStores();

  return (
    <SafeAreaView style={screenStyle}>
      <Text style={headingStyle} variant="displayMedium">
        Playlista
      </Text>

      { songsStore.playlistSongs.length === 0 && <>
        <Text style={headingStyle} variant="bodyLarge">
          Brak piosenek na playliście. Użyj ikony playlisty, aby dodać piosenkę do playlisty.
        </Text>

        <Text style={headingStyle}>
          <Icon source="playlist-plus" size={iconSize} />
        </Text>
      </> }

      <SongsList data={songsStore.playlistSongs} />
    </SafeAreaView>
  )
})
