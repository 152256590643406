import { observer } from "mobx-react-lite";
import {
  Portal,
} from 'react-native-paper';

import { ProgressDialog } from "../../src/@components/ProgressDialog";
import { ComingSoonDialog } from "../../src/@components/ComingSoonDialog";

export const GlobalPortal = observer(() => {
  return (
    <Portal>
      <ProgressDialog />
      <ComingSoonDialog />
    </Portal>
  )
})
