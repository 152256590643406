import { makeAutoObservable } from "mobx"

import { getFontSizeModifier, setFontSizeModifier } from "../../src/@services/storage/SettingsStorage";

export class FontSizeStore {
  barVisible = false
  modifier = 0

  constructor() {
    makeAutoObservable(this)
    this.loadModifier()
  }

  loadModifier = async () => {
    const modifier = await getFontSizeModifier();
    this.modifier = modifier;
  }

  saveModifier = () => {
    return setFontSizeModifier(this.modifier);
  }

  toggleBar = () => {
    this.barVisible = !this.barVisible;
  }

  showBar = () => {
    this.barVisible = true;
  }

  hideBar = () => {
    this.barVisible = false;
  }

  increase = () => {
    this.modifier++;
    this.saveModifier();
  }

  decrease = () => {
    this.modifier--;
    this.saveModifier();
  }

  reset = () => {
    this.modifier = 0;
    this.saveModifier();
  }
}
