import * as React from 'react';
import { FlatList } from 'react-native';
import { Divider } from 'react-native-paper';
import { observer } from "mobx-react-lite"

import { SongListItem } from '../../src/@components/SongListItem';

const renderItem = ({ item }) => {
  return (
    <SongListItem song={item} />
  );
};

export const SongsList = observer((props) => {
  const { data } = props;

  return (
    <FlatList
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      ItemSeparatorComponent={Divider}
      data={data}
      alwaysBounceVertical={false}
      initialNumToRender={25}
      windowSize={2}
      removeClippedSubviews={true}
      getItemLayout={(_data, index) => (
        {length: 47, offset: 47 * index, index}
      )}
    />
  )
})
