import { makeAutoObservable } from "mobx"

import { emit, connect, disconnect } from '../../src/@services/api/Broker';
import { songsStore } from '../../src/@stores/useStores';

export class ListenerStore {
  enabled = false
  validTo = null

  // state from socket
  active = false
  connected = false
  disconnected = true
  socketId = null

  sentSong = {}
  receivedSong = {}

  constructor() {
    makeAutoObservable(this)
  }

  toggle = () => {
    console.log(songsStore.activeSong);
    if (this.enabled) {
      this.enabled = false;
      disconnect();
    } else {
      this.enabled = true;
      connect();
    }
  }

  setState(socket) {
    this.active = socket.active;
    this.connected = socket.connected;
    this.disconnected = socket.disconnected;
    this.socketId = socket.id;
  }

  broadcast = () => {
    if (!this.enabled || !this.currentSong.id) { return; }

    this.sentSong = this.currentSong;
    emit('CHOOSE_SONG', this.currentSong);
  }

  receive = (msg) => {
    if (!this.enabled) { return; }

    const { metadata, data } = msg || {};
    if (!data) { return; }

    this.receivedSong = data;

    if (metadata.socketId === this.socketId) { return; }
    songsStore.setActive(data);
  }

  setValidTo = (validTo) => {
    this.validTo = validTo;
  }

  get currentSong() {
    return songsStore.activeSong || {};
  }

  get machineState() {
    if (!this.connected) {
      return 'DISCONNECTED';

    } else if (this.currentSong.id && this.currentSong.id !== this.receivedSong.id) {
      return 'READY_TO_SEND';

    } else if (this.receivedSong.id && this.receivedSong.id === this.currentSong.id) {
      return 'RECEIVED';

    } else if (this.sentSong.id && this.sentSong.id !== this.receivedSong.id) {
      return 'SENDING';

    } else {
      return 'IDLE';
    }
  }
}
