let searchInputRef = null;

export const setSearchInputRef = (ref) => {
  searchInputRef = ref;
};

export const focusSearchInput = () => {
  if (!searchInputRef) { return; }

  searchInputRef.current.focus();
};
