import AsyncStorage from '@react-native-async-storage/async-storage';

import { STORAGE_PREFIX } from '../../../src/@constants/storage'

// NOTE: this is async
export const setItem = (key, value) => {
  return AsyncStorage.setItem(`${STORAGE_PREFIX}/${key}`, JSON.stringify(value));
}

export const getItem = async (key, defaultValue = null) => {
  return JSON.parse(await AsyncStorage.getItem(`${STORAGE_PREFIX}/${key}`)) || defaultValue;
}
