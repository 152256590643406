import { makeAutoObservable } from "mobx"

export class SongFabStore {
  open = false

  constructor() {
    makeAutoObservable(this)
  }

  setOpen = (val) => {
    this.open = val;
  }
}
