import { getItem, setItem } from '../../../src/@services/storage/AsyncStorageHelpers';

export const getSongs = () => {
  return getItem('songs');
}

export const setSongs = (songs) => {
  return setItem('songs', songs);
}

export const getFavorites = () => {
  return getItem('favorites', []);
}

export const setFavorites = (songs) => {
  return setItem('favorites', songs);
}

export const getPlaylist = () => {
  return getItem('playlist', []);
}

export const setPlaylist = (songs) => {
  return setItem('playlist', songs);
}
